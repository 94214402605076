<template>
  <div>
    <a-form :form="form">
      <a-form-item
        label="二维码更新时间"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-date-picker placeholder="请选择日期" v-decorator="['updateDate', { initialValue: date }]" />
      </a-form-item>
      <a-form-item
        label="二维码上传"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <upload-file-to-oss
          ref="uploadfile"
          type="VPN_QRCODE"
          :relateId="0"
          :preview="true"
          :maxQuantity="1"
        ></upload-file-to-oss>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import UploadFileToOss from "@/components/UploadFileToOss.vue";
import { editRocketConfig, getRocketConfig } from "@/api/laboratory";
import moment from "moment";
export default {
  name: "QrCodeConfig",
  components: {
    UploadFileToOss,
  },
  props: {},
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      date:undefined
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getRocketConfig().then(res => {
        this.date = moment(res.data.updateDate)
      })
    },
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            editRocketConfig({
              id: 0,
              updateDate: moment(values.updateDate).format("YYYY-MM-DD"),
              uploadType: "VPN_QRCODE",
            }).then(() => {
              this.$refs.uploadfile.handleSave(0).then(() => {
                resolve(true);
                this.$message.success('配置成功')
              });
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
