<template>
  <div>
    <a-table :columns="columns" :data-source="data">
      <a slot="action" slot-scope="text, record" @click="handle(record.key)">
        {{ text }}
      </a>
    </a-table>
  </div>
</template>

<script>
import QrCodeConfigVue from "./vpn-config/QrCodeConfig.vue";
export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      columns: [
        {
          title: "模块名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "配置",
          dataIndex: "action",
          align: "center",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
      data: [
        {
          name: "聊天机器人",
          action: "编辑 ai 对话平台链接",
          key: "chat",
        },
        {
          name: "网络加速器",
          action: "编辑 VPN 配置二维码",
          key: "vpn",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    handle(key) {
      switch (key) {
        case "chat":
          this.editChatLinks();
          break;
        case "vpn":
          this.editVpn();
          break;
      }
    },
    editChatLinks() {
      if (this.hasRouter("chat-robot-config")) {
        this.$router.push({
          path: "/system/liboratory/chat-links-list",
        });
      } else {
        this.$message.error("无权限");
      }
    },
    editVpn() {
      if (this.hasAction("rocket-config")) {
        this.$dialog(
          QrCodeConfigVue,
          {},
          {
            title: "编辑VPN",
            width: 600,
            centered: true,
            maskClosable: false,
          }
        );
      } else {
        this.$message.error("无权限");
      }
    },
  },
};
</script>

<style scoped></style>
