import request from "@/utils/request";

const laboratoryApi = {
  // 聊天机器人链接
  GetChatbotList: "/lab/chatbot-list",
  AddChatbot: "/lab/add-chatbot",
  EditChatbot: "/lab/edit-chatbot",
  DeleteChatbot: "/lab/del-chatbot",
  MoveChatbot: "/lab/move",
  EditRocketConfig: "/lab/edit-vpn",
  GetRocketConfig: "/lab/get-vpn-qrcode",
};

export function getChatbotList() {
  return request({
    url: laboratoryApi.GetChatbotList,
    method: "get",
  });
}

export function addChatbot(parameter) {
  return request({
    url: laboratoryApi.AddChatbot,
    method: "post",
    data: parameter,
  });
}

export function editChatbot(parameter) {
  return request({
    url: laboratoryApi.EditChatbot,
    method: "post",
    data: parameter,
  });
}

export function deleteChatbot(parameter) {
  return request({
    url: laboratoryApi.DeleteChatbot + `/${parameter.chatBotResourceId}`,
    method: "post",
  });
}

export function moveChatbot(parameter) {
  return request({
    url: laboratoryApi.MoveChatbot,
    method: "post",
    data: parameter,
  });
}

export function editRocketConfig(parameter) {
  return request({
    url: laboratoryApi.EditRocketConfig,
    method: "post",
    data: parameter,
  });
}

export function getRocketConfig() {
  return request({
    url: laboratoryApi.GetRocketConfig,
    method: "get",
  });
}
